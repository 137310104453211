import styled, { css } from 'styled-components'
import { Media } from '@raylink/common/theme'
import { Warpper } from '@raylink/common/components'

const DefaultStyle = css`
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
  }
  h3 {
    font-size: 1.17em;
    margin: 0.83em 0;
  }
  h4,
  p {
    margin: 1.12em 0;
  }
  h5 {
    font-size: 0.83em;
    margin: 1.5em 0;
  }
  h6 {
    font-size: 0.75em;
    margin: 1.67em 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-weight: bolder;
  }
`

export const Wrapper = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 700px;
  padding-top: 50px;
  .notify-title {
    width: 1200px;
    word-wrap: break-word;
  }
  ${DefaultStyle}
  ${Media.phone`
    padding: 0 0.4rem;
    .notify-title {
      width: 100%;
    }
  `}
`
