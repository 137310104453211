import React, { useEffect, useState } from 'react'
import { Layout, TitleH2 } from '@raylink/common/components'
import { API, Axios } from '@raylink/common/utils'
import { Wrapper } from './notifyDetai.style'

const NotifyDetail = () => {
  const [message, setMessage] = useState('')
  useEffect(() => {
    const instance = new Axios(API.auth.host)
    instance
      .get(
        `${API.auth.queryNotifyDetail}?id=${new URLSearchParams(
          window.location.search
        ).get('id')}`
      )
      .then(res => {
        setMessage(res?.data || {})
      })
  }, [])
  return (
    <Layout isWithoutNotify>
      <Wrapper>
        <TitleH2 className="notify-title">{message?.title}</TitleH2>
        <div
          style={{
            width: '80%',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
          dangerouslySetInnerHTML={{ __html: message?.content }}
        />
      </Wrapper>
    </Layout>
  )
}

export default NotifyDetail
